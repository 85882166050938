







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class PhraseLinkEmitClick extends Vue {
  @Prop({ required: true})
  content!: string;

  @Watch('content')
  async findAClick() {
    await this.$nextTick();
    const links = (this.$refs.span as HTMLSpanElement).querySelectorAll(`a`);
    if (!links.length){
      return;
    }

    links.forEach(a => {
        a.removeEventListener('click', this.onClick);
        a.addEventListener('click', this.onClick)
    })
  }

  mounted() {
    this.findAClick();
  }

  beforeDestroy() {
    const links = (this.$refs.span as HTMLSpanElement).querySelectorAll(`a`);
    if (!links.length){
      return;
    }
    links.forEach(a => {
        a.removeEventListener('click', this.onClick)
    })
  }

  onClick(e: Event) {
    this.$emit('click', e);
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
}
