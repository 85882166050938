






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import FInput from '@/components/Form/FInput.vue';
import FButton from '@/components/Button/FButton.vue';
import { GTMService } from '@/services/GTMService';

@Component({
  components:{
    FInput,
    FButton
  }
})
export default class MultipleItemsInput extends Vue {

  @Prop({ default: 'Label'})
  label!: string;

  @Prop({ default: 'item'})
  itemNameSingular!: string;

  @Prop({ default: 'items'})
  itemNamePlural!: string;

  @Prop()
  value!: string[];

  @Prop({default: null})
  exactLength!: number | null;

  @Prop({default: ''})
  helpText!: string;

  @Prop({ default: false, type: Boolean})
  capitalize!: boolean;

  @Prop({ default: null})
  addedGaEvent!: string | null;

  @Prop({ default: null})
  pattern!: string | null;

  @Prop({ default: 'text'})
  type!: string;

  newInput = '';

  forceShowNewError = false;

  get customErrs() {
    const errMsgs = this.val.map(i => this.val.filter(i2 => i2 === i).length > 1 ? this.$t('R.MES_MultiItem_DuplicateItemFound', {item: this.itemNameSingular}): '');
    return errMsgs
  }


  onInput(index: number | null){
    if (this.capitalize){
      if (index === null){
        this.newInput = this.newInput.toUpperCase();
      }else if (this.val[index]){
        this.val[index] = this.val[index].toUpperCase();
      }
    }
  }

  get val(){
    return this.value;
  }

  set val(newValue){
    this.$emit('input', newValue);
  }

  get notYetAdded(){
    if (this.newInput){
      return this.$t('R.MES_MultiItem_NewItemNotYetAdded')
    }
    return '';
  }

  get duplicatesFound(){
    if ( this.val.includes(this.newInput)){
      return this.$t('R.MES_MultiItem_DuplicateItemFound', {item: this.itemNameSingular})
    }
    return '';
  }

  get newCustomErr(){
    return this.duplicatesFound || '';
  }

  onAdd(){
    this.forceShowNewError = false;

    if (!this.newInput){
      return;
    }

    if (this.exactLength && this.newInput.length !== this.exactLength || this.duplicatesFound){
      this.forceShowNewError = true;
      return;
    }

    if (!(this.$refs.newinput as FInput).checkValidity()){
      this.forceShowNewError = true;
      return;
    }

    if (this.addedGaEvent){
      GTMService.pushCustomEvent({event: this.addedGaEvent})
    }

    this.val.push(this.newInput);
    this.newInput = '';
  }

  removeIndex(index: number){
    this.val.splice(index, 1);
  }


}
