







import { ChordProService } from '@/services/ChordProService'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CPTextArea extends Vue {
  store = ChordProService

  mounted () {
    this.store.init(this.$refs.editor as HTMLElement)
  }
}
