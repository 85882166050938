











































import { Component, Vue } from "vue-property-decorator";
import HelpIcon from '@/components/HelpIcon/HelpIcon.vue';
@Component({ 
  components:{ 
    HelpIcon,
  }, 
})
export default class CatalogHelpIcon extends Vue {
}
