



























import Modal from '@/components/Modal/Modal.vue';
import { EntryStage, RequestType } from '@/configs/SongRegistrationConfig';
import { bus, i18n } from '@/main';
import { CustomError } from '@/models/CustomError';
import { FELyricPart } from '@/models/frontendOnly/FELyricPart';
import { SongWriterDetails } from '@/models/SongWriterDetails';
import { BusyService } from '@/services/BusyService';
import { RegisterSongService } from '@/services/RegisterSongService';
import { ToastService } from '@/services/ToastService';
import { Component, Prop, Vue } from 'vue-property-decorator'
import AddChordsSetupModal from './AddChordsSetupModal.vue';
import RegisterSongStep4Done from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep4Done.vue'
import SongChordEditorModal from './SongChordEditorModal.vue';
import { ChordProService } from '@/services/ChordProService';
import EditSongLyricsModal from './EditSongLyricsModal.vue';
import { uniq } from 'lodash';

@Component({
  components:{
    SongChordEditorModal,
    AddChordsSetupModal,
    RegisterSongStep4Done,
    Modal,
  }
})
export default class EditSongChordsModal extends Vue {
  completed = false;
  title = '';
  chords = '';
  lyrics: FELyricPart[] = [];
  songwriters: SongWriterDetails[] = [];
  editMode_nAddMode = true;
  registerMode = true;
  editingChordsAfterLyricChanges = false;

  @Prop()
  editLyricsRef!: EditSongLyricsModal;

  get modalTitle(){
    return this.editMode_nAddMode ?
      this.$t('R.LIT_EditChords') :
      this.$t('R.LIT_AddChords')
  }


  editSongChords(title: string, chords: string, authors: string, lyrics: FELyricPart[], registerMode = true ){
    this.editingChordsAfterLyricChanges = false;
    this.editMode_nAddMode = true;
    this.title = title;
    this.chords = chords;
    this.registerMode = registerMode;
    this.lyrics = lyrics;
    if (!this.registerMode){
      this.loadSong();
    }
    (this.$refs.editModal as SongChordEditorModal).init(this.title, this.chords, authors, this.lyrics, true);
  }

  editSongChordsAfterLyricEdits(){
    this.editingChordsAfterLyricChanges = true;
    if (!RegisterSongService.data){
      return;
    }
    this.editMode_nAddMode = true;
    this.title = RegisterSongService.data.songTitle;
    this.chords = RegisterSongService.data.chords;
    this.registerMode = false;
    this.lyrics = RegisterSongService.data.lyrictext;
    (this.$refs.editModal as SongChordEditorModal).init(this.title, this.chords, RegisterSongService.data.songWriters.map(w => w.displayName).join(', '), this.lyrics, true);
  }

  addSongChords(title: string, lyrics: FELyricPart[], songwriters: SongWriterDetails[], registerMode = true) {
    this.editingChordsAfterLyricChanges = false;
    this.title = title;
    this.editMode_nAddMode = false;
    this.lyrics = lyrics;
    this.songwriters = songwriters;
    this.registerMode = registerMode;
    (this.$refs.addChordSetupModal as AddChordsSetupModal).show();
  }

  async onSetupChords(data: { key: string; tempo: string; time: string;}){
    const chords = RegisterSongService.generateInitialChords(
      data,
      this.lyrics
    );
    if (!this.registerMode){
      this.loadSong();
    }
    (this.$refs.editModal as SongChordEditorModal).init(this.title, chords, this.songwriters.map(s => s.displayName).join(', '), this.lyrics, false);
  }

  async loadSong(){
    BusyService.showBusy();
    await RegisterSongService.loadEditSong(
      this.$route.params.ccliSongId,
      this.editMode_nAddMode ? RequestType.EditChords : RequestType.AddChords
    );
    this.completed = false;
    if ((this.$refs.editModal as SongChordEditorModal)){
      (this.$refs.editModal as SongChordEditorModal).restrictedWordsError = false;
    }
    BusyService.hideBusy();
  }

  async handleError(err: any) {
    if ((err as any)?.data?.payload) {
      if (typeof (err as any).data.payload === 'object') {
        if ((err as any).data.payload.unrecognizedChords.length) {
          const chords = uniq<string>((err as any).data.payload.unrecognizedChords.map((c: {
            text: string
          }) => c.text));
          ChordProService.updateWithInvalidChords(chords);
          (this.$refs.editModal as SongChordEditorModal).invalidChordsError = true;
          (this.$refs.editModal as SongChordEditorModal).showErrors = true;
        }

        if ((err as any).data.payload.generalIssues.countErrors && (err as any).data.payload.generalIssues.logText) {
          (this.$refs.editModal as SongChordEditorModal).generalIssuesError = (err as any).data.payload.generalIssues.logText;
        }

      } else if ((err as any)?.data?.publicStatusMessage === 'RESTRICTED_WORDS') {
        (this.$refs.editModal as SongChordEditorModal).restrictedWordsError = true;

        if (typeof (err as any).data.payload === 'string') {
          const stringInError = (err as any).data.payload as string;
          (this.$refs.editModal as SongChordEditorModal).stringInError = stringInError;
          ChordProService.updateWithRestrictedWords([stringInError])
        } else {
          const stringInError = (err as any).data.payload as string[]
          (this.$refs.editModal as SongChordEditorModal).stringInError = stringInError.join(', ');
          ChordProService.updateWithRestrictedWords(stringInError);
        }
      }
    } else {
      ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
      throw new CustomError(err, false, 'Reason code not recognized');
    }
  }


  async onSave(chords: string, closeModal: boolean){
    if (!RegisterSongService.data){
      return;
    }

    RegisterSongService.data.chords = chords;
    BusyService.showBusy();
    try{
      if (this.editingChordsAfterLyricChanges){
        RegisterSongService.data.requestType = RequestType.EditLyrics
        const c = RegisterSongService.data.chords;
        await RegisterSongService.save();
        RegisterSongService.data.requestType = RequestType.EditChords
        RegisterSongService.data.chords = c;
      }
      await RegisterSongService.save();

      if (RegisterSongService.data.requestType === RequestType.AddChords){
        RegisterSongService.data.requestType = RequestType.EditChords;
      }
      (this.$refs.editModal as SongChordEditorModal).refreshOldChordsData();
      this.completed = true;

      ToastService.successToast(this.$t('R.MES_SongChangesSaved').toString());
      bus.$emit('refreshSong');
      if (closeModal){
        (this.$refs.editModal as SongChordEditorModal).hide();
        if (!this.registerMode){
          (this.$refs.doneModal as Modal).show();
        }
      }
      BusyService.hideBusy();
    }catch(err){
      BusyService.hideBusy();
     this.handleError(err);
    }
  }

  onCancel(){
    this.editLyricsRef?.show(true);
  }
}
