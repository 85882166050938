





























































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import FButton from '@/components/Button/FButton.vue';
import { RegisterSongService } from '@/services/RegisterSongService';
import { SongWriterDetails } from '@/models/SongWriterDetails';
import { EntryStage } from '@/configs/SongRegistrationConfig';
import EmptyBadge from '@/components/Badge/EmptyBadge.vue';
import ChordProPreview from '@/components/ChordProEditor/ChordProPreview.vue';
import { ProfileService } from '@/services/ProfileService';

@Component({
  components:{
    FButton,
    EmptyBadge,
    ChordProPreview
  }
})
export default class SongPreview extends Vue {
  store = RegisterSongService;

  entryStage = EntryStage;

  get featureToggleChords(){
    return ProfileService.profile?.featureToggleChords;
  }

  @Prop({ default: false, type: Boolean})
  hideEdits !: boolean;

  getContributions(s: SongWriterDetails){
    const strArray: string[] = [];

    if (s.wroteWords){
      strArray.push(this.$t('R.LIT_WroteTheLyrics').toString());
    }
    if (s.wroteMusic){
      strArray.push(this.$t('R.LIT_SongWriterComposedMusic').toString());
    }
    if (!strArray.length){
      return '';
    }
    return `(${strArray.join(', ')})`;
  }
}
