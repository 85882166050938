
































































































































import { RegisterSongService } from '@/services/RegisterSongService';
import { Component, Vue, Watch } from 'vue-property-decorator'
import RegisterSongStep from './RegisterSongStep.vue'
import RegisterSongStep0SelectACatalog from './RegisterSongStep0SelectACatalog.vue';
import RegisterSongStep1EnterSongDetails from './RegisterSongStep1EnterSongDetails.vue';
import RegisterSongStep2EnterLyrics from './RegisterSongStep2EnterLyrics.vue';
import RegisterSongStep2bEnterChords from './RegisterSongStep2bEnterChords.vue';
import RegisterSongStep3ReviewAndRegisterSong from './RegisterSongStep3ReviewAndRegisterSong.vue';
import RegisterSongStep4Done from './RegisterSongStep4Done.vue';
import LargeIcon from '@/components/Icon/LargeIcon.vue';
import Modal from '@/components/Modal/Modal.vue'
import { EntryStage } from '@/configs/SongRegistrationConfig';
import { NavigationGuardNext, Route } from 'vue-router';
import ConfirmService from '@/services/ConfirmService';
import { i18n } from '@/main';
import { ProfileService } from '@/services/ProfileService';

@Component({
  components:{
    Modal,
    RegisterSongStep,
    RegisterSongStep0SelectACatalog,
    LargeIcon,
    RegisterSongStep1EnterSongDetails,
    RegisterSongStep2EnterLyrics,
    RegisterSongStep2bEnterChords,
    RegisterSongStep3ReviewAndRegisterSong,
    RegisterSongStep4Done,
  },
  beforeRouteLeave: function(to: Route, from: Route, next: NavigationGuardNext) {
    const enableNavGuard = RegisterSongService.enableNavGuard();

    if (enableNavGuard) {
      ConfirmService.showConfirm({
        title: i18n.t('R.LIT_ContinueWithoutSaving').toString(),
        message: i18n.t('R.MES_LeaveWithoutSaving').toString(),
        okLabel: i18n.t('R.LIT_ContinueWithoutSaving').toString(),
        cancelLabel: i18n.t('G.LIT_Cancel').toString()
      }).then(success => {
        if (success){
          next();
        }else{
          next(false);
        }
      });
    }else{
      next();
    }
  }
})
export default class RegisterSongDetails extends Vue {
  store = RegisterSongService;

  entryStage = EntryStage;

  @Watch('currentStage')
  onCurrentStageChange() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  get featureToggleChords() {
    return ProfileService.profile?.featureToggleChords;
  }

  get requestId(){
    return this.$route.params.id;
  }

  get currentStage(){
    return this.store.data?.currentStage || 0;
  }

  set currentStage(stage: EntryStage) {
    if (!this.store.data){
      return;
    }
    this.store.data.currentStage = stage;
  }

  mounted(){
    this.store.loadSong(this.requestId);
  }

  get locked() {
    return this.currentStage >= EntryStage.Done;
  }
}
