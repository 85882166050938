







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PhraseLink extends Vue {
  @Prop({ required: true})
  content!: string;

  @Prop({ default: false, type: Boolean})
  newTab!: boolean;

  mounted() {
    const links = (this.$refs.span as HTMLSpanElement).querySelectorAll(`a[href^="/"]`);
    if (!links.length){
      return;
    }

    links.forEach(a => {
      if (this.newTab){
        a.setAttribute('target','_blank');
      }else{
        a.addEventListener('click', this.onClick)
      }
    })
  }

  beforeDestroy() {
    if (!this.newTab){
      return;
    }
    const links = (this.$refs.span as HTMLSpanElement).querySelectorAll(`a[href^="/"]`);
    if (!links.length){
      return;
    }

    links.forEach(a => {
      a.removeEventListener('click', this.onClick)
    })
  }

  onClick(e: Event) {
    if (this.newTab){
      return;
    }

    const element = e.target as HTMLAnchorElement;
    const href = element.getAttribute('href') || '/';
    this.$router.push(href);
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
}
