






















import shortUUID from 'short-uuid'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Dropdown extends Vue {
  @Prop({ default: () => shortUUID.generate() })
  id!: string;

  @Prop({ default: '' })
  label!: string;

  ref: any;
}
