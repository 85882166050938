import { render, staticRenderFns } from "./EmptyBadge.vue?vue&type=template&id=1ed46fe5&scoped=true&"
import script from "./EmptyBadge.vue?vue&type=script&lang=ts&"
export * from "./EmptyBadge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed46fe5",
  null
  
)

export default component.exports