



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class FTextarea extends Vue {
  @Prop({ default: '' })
  label!: string;

  @Prop({ default: false, type: Boolean })
  required!: boolean;

  @Prop({ default: false, type: Boolean })
  hiddenLabel!: boolean;

  @Prop({ default: false, type: Boolean })
  search!: boolean;

  @Prop({ default: '', })
  placeholder!: string;

  @Prop({ default: '', })
  rows!: string;

  @Prop()
  value !: string;

  @Prop({default: false, type: Boolean})
  autogrow!: boolean;

  @Prop({default: () => ([])})
  customErrors!: string[];

  get val() { 
    this.autoGrowHeight();
    return this.value;
  }

  set val(value){ 
    this.$emit('input', value);
  }

  height = 100;
  async autoGrowHeight() { 
    if (!this.autogrow || !this.$refs.textarea){ 
      return;
    }
    
    this.height = 1;
    await this.$nextTick();
    
    if (!this.$refs.textarea){
      return;
    }

    this.height = (this.$refs.textarea as HTMLTextAreaElement).scrollHeight;
  }

  @Watch('customErrors', { deep: true})
  applyCustomError(){
    if (this.$refs.textarea){
      (this.$refs.textarea as HTMLTextAreaElement).setCustomValidity(this.customErrors.length ? this.customErrors.join('\n'): '')
    }
  }

  mounted() { 
    this.autoGrowHeight();
    this.applyCustomError();
  }  
}
