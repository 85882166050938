










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ButtonGroup extends Vue {
  @Prop()
    title?:string
}
