












































import { Component, Vue } from 'vue-property-decorator'
import ModalForm from '@/components/Modal/ModalForm.vue';
import FInput from '@/components/Form/FInput.vue';
import AvailableKeys from '@/assets/json/AvailableKeys.json';
import { SelectOption } from '@/models/SelectOption';
import FSelect from '@/components/Form/FSelect.vue';

@Component({
  components:{
    ModalForm,
    FSelect,
    FInput
  }
})
export default class AddChordsSetupModal extends Vue {


  get availableKeys(): SelectOption[] {
    return AvailableKeys.map(a => ({
      label: a,
      value: a
    }))
  }

  keyTypeOptions: SelectOption[] = [{
    label: this.$t('R.LIT_Major').toString(),
    value: 'major'
  },{
    label:  this.$t('R.LIT_Minor').toString(),
    value: 'minor'
  }]

  keyType = "major";
  key = '';

  data = {
    key: '',
    tempo: '',
    time: '',
  };

  show(){
    this.keyType = 'major';
    this.key = '';
    this.data = {
      key: '',
      tempo: '',
      time: '',
    };
    (this.$refs.modal as ModalForm).show();
  }

  onSubmit(){
    if (this.keyType === 'minor'){
      this.data.key = this.key+'m';
    }else{
      this.data.key = this.key;
    }
    (this.$refs.modal as ModalForm).hide();
    this.$emit('submit', this.data);
  }
}
