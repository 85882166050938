


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CPTextAreaToolbar from './ChordProTextAreaToolbar.vue'
import CPTextArea from './ChordProTextArea.vue'
import ChordProPreview from './ChordProPreview.vue'
import { ChordProService } from '@/services/ChordProService'
import { FELyricPart } from '@/models/frontendOnly/FELyricPart'

@Component({
  components: {
    CPTextAreaToolbar,
    CPTextArea,
    ChordProPreview
  }
})
export default class ChordProEditor extends Vue {
  store = ChordProService

  @Prop({ type: String, default: '' })
    title!: string;

  @Prop({ type: String, default: '' })
    authors!: string;

  @Prop({ type: String, default: '' })
    value!: string

  @Prop({ default: () => ([])})
    lyrics!: FELyricPart[]

  dataChanging = false
  mounted () {
    this.store.updateData(this.value)
  }

  @Watch('value')
  onValueChange () {
    if (!this.dataChanging) {
      this.store.updateData(this.value)
    }
  }

  @Watch('store.data')
  async onDataChange () {
    this.dataChanging = true
    this.$emit('input', this.store.data)
    await this.$nextTick()
    this.dataChanging = false
  }
}
