

















import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
export default class Button extends Vue {
    @Prop({ default: 'button' })
      type!: 'button' | 'submit' | 'reset'

    @Prop({ default: null })
      icon!: string[] | null

    @Prop({ type: Boolean, default: false })
      small!: boolean

    @Prop({ type: String })
      title?: string

    onEmit(e:Event){
      this.$emit('click', e);
    }

    get btnClasses(){
      const c = [] as string[];
      if (this.small){
        c.push('btn-sm');
      }

      return c.join(' ');
    }
}
