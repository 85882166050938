import { render, staticRenderFns } from "./AddChordsSetupModal.vue?vue&type=template&id=7ee7fed3&scoped=true&"
import script from "./AddChordsSetupModal.vue?vue&type=script&lang=ts&"
export * from "./AddChordsSetupModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ee7fed3",
  null
  
)

export default component.exports