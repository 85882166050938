

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Blank extends Vue {
  @Prop({ default: false, type: Boolean })
  complete!: boolean;

  @Prop({ default: false, type: Boolean })
  current!: boolean;

  @Prop({ default: false, type: Boolean})
  solid !: boolean;

  @Prop({ default: false, type: Boolean})
  locked !: boolean;

  get icon() {
    if (this.solid && this.complete){ 
      return ['fas','check-circle']
    }else{
      return ["fal", this.complete ? "check-circle" : "circle"];
    }
  }
}
