import { render, staticRenderFns } from "./FTextarea.vue?vue&type=template&id=396bdb51&scoped=true&"
import script from "./FTextarea.vue?vue&type=script&lang=ts&"
export * from "./FTextarea.vue?vue&type=script&lang=ts&"
import style0 from "./FTextarea.vue?vue&type=style&index=0&id=396bdb51&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396bdb51",
  null
  
)

export default component.exports